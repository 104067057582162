@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  @apply bg-light-grey;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-table {
  overflow-x: auto;
}

:where(.css-dev-only-do-not-override-tpassh).ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-tpassh).ant-table-wrapper .ant-table-thead > tr > td {
  @apply bg-light-grey;
}

:where(.css-dev-only-do-not-override-tpassh).ant-layout-sider .ant-layout-sider-trigger {
  position: relative;
  border-inline-end: 1px solid rgba(5, 5, 5, 0.06);
}

.ant-table-title {
  background-color: #e6f4ff;
}

.ant-table-cell {
  text-align: center !important;
}

:where(.css-dev-only-do-not-override-tpassh).ant-image-preview-root .ant-image-preview-img {
  object-fit: contain;
}

.ant-tabs-content.ant-tabs-content-top {
  height: 100%;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
  height: 100%;
}

.ant-modal-footer,
.ant-modal-confirm-btns {
  @apply flex md:block flex-wrap items-center gap-2;

  .ant-btn {
    @apply !ms-0 md:!ms-2 shadow grow;
  }
}

.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
  visibility: visible !important;
}

.ant-picker-panels > *:last-child {
  display: none;
}

.ant-picker-footer-extra > div {
  flex-wrap: wrap !important;
}

.ant-picker-panel-layout {
  @apply flex-col md:flex-row;
}

:where(.css-dev-only-do-not-override-tpassh).ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets {
  @apply max-w-none;
}

:where(.css-dev-only-do-not-override-tpassh).ant-picker-dropdown .ant-picker-panel-container .ant-picker-presets ul {
  @apply flex items-center h-auto border-b md:block md:h-0 md:border-b-0;
}

:where(.css-dev-only-do-not-override-tpassh).ant-picker-dropdown
  .ant-picker-panel-container
  .ant-picker-presets
  ul
  li
  + li {
  @apply mt-0 md:mt-2;
}

@media (max-width: 575px) {
  form.role-form.ant-form:not(.ant-form-inline) .ant-form-item .ant-form-item-label:not([class*=" ant-col-xs"]) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    text-align: end;
  }

  form.role-form.ant-form:not(.ant-form-inline) .ant-form-item .ant-form-item-control:not([class*=" ant-col-xs"]) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0;
    margin: 0;
    white-space: nowrap;
  }
}
