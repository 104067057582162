/* Main app container styling */
.app-container {
  display: flex;
  height: 100vh; /* Full viewport height */
  transition: all 0.3s ease;
}

/* Sidebar styling */
.sidebar {
  height: 100%; /* Full container height */
  width: 200px;
  padding: 20px;
  background-color: #333;
  color: white;
  transition: width 0.3s ease, padding 0.3s ease;
}

.sidebar.open {
  width: 200px;
}

.sidebar.closed {
  width: 60px;
  padding: 20px 5px;
}

.sidebar h2 {
  font-size: 18px;
  margin: 20px 0;
  transition: opacity 0.3s ease;
}

/* Hide the sidebar title when closed */
.sidebar.closed h2 {
  opacity: 0;
}

/* Main content styling */
.main-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto; /* Allows scrolling if content exceeds viewport height */
  transition: margin-left 0.3s ease;
}

.app-container.sidebar-closed .main-content {
  margin-left: 60px;
}

/* Toggle button styling */
.toggle-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
}

.toggle-button:hover {
  background-color: #0056b3;
}

/* Content area styling */
.content {
  padding: 20px;
  background-color: white;
  transition: margin-left 0.3s;
}

/* Center alignment for General and file input */
.header-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.header-center h2 {
  margin: 0;
}

.header-center input[type="file"] {
  margin-top: 10px;
}

/* Filter Section */
.filters-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  border-right: 2px solid #ccc;
}

.filter-group:last-child {
  border-right: none;
}

.filters-row button,
.filters-row select {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.filters-row button.active,
.filters-row select.active {
  background-color: #28a745; /* Green active color */
}

.filters-row button:hover,
.filters-row select:hover {
  background-color: #0056b3;
}

/* Metrics Section */
.metrics-row {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin: 20px 0;
}

.metric-box {
  background-color: #084c61;
  color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 150px;
}

.metric-box h3 {
  margin: 0;
  font-size: 16px;
}

.metric-box p {
  margin: 10px 0 0;
  font-size: 24px;
  font-weight: bold;
}

/* Chart Section */
.chart-section {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.chart {
  width: 45%;
}

/* Styling for group separation in filters */
.filter-group {
  padding: 10px;
  border-right: 1px solid #ccc;
}

.filter-group:last-child {
  border-right: none;
}
